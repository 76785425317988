import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { InformationService } from './information.service';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class NsfwpostService {

  constructor(private info: InformationService, private storage: AngularFireStorage, private firestore: AngularFirestore) { }

  submitPost(bAnonymous: boolean, oUser: User, sMessage: string, oAttachment: any): Promise<PostSubmit> {
    let d = new Date();
    return new Promise((resolve, reject) => {

      let d2 = new Date();
      d2.setMinutes(d2.getSeconds() - 30);
      //Get the last post the user made
      this.firestore.firestore.collection('nsfw').where('userid', '==', oUser.id).orderBy('date', 'desc').limit(1).get().then((ret) => {
        if (oUser.id == "ns8oXE44pme5qxG7YpVQxTFjaL63" || oUser.id == "ytbN0kzveFZiHWs3SaEPPfsEM7J3" || ret.empty || ret.docs[0].data().date.toDate() < d2) {
          //They can post
          this.firestore.firestore.collection('nsfw').add({ userid: oUser.id, username: bAnonymous ? "Anonymous" : oUser.username, message: sMessage, date: d, oofs: [], rips: [], fs: [], points: 0, reports: 0, show: true }).then((docRef) => {
            if (oAttachment != null) {
              this.storage.storage.ref('nsfw/' + docRef.id).put(oAttachment, { contentType: oAttachment.type }).then((fileTask) => {
                fileTask.task.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                  let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  this.info.showToast("Uploading post: " + progress);
                }, (err) => {
                  this.info.showToast("Error adding image to post.");
                }, () => {
                  fileTask.ref.getDownloadURL().then((value) => {
                    // console.log("download url val", value);
                    this.firestore.firestore.collection('nsfw').doc(docRef.id).update({ attachmentURL: value }).then(() => {
                      resolve(<PostSubmit>{ id: docRef.id, attachmentURL: value });
                    }).catch((err) => {
                      console.log(err);
                      reject();
                    });
                  });
                })
              }).catch((err) => {
                console.log(err);
                reject();
              });
            } else {
              resolve(<PostSubmit>{ id: docRef.id, attachmentURL: null });
            }
          }).catch((err) => {
            console.log(err);
            reject();
          });
        } else {
          this.info.showToast("You have to wait longer between posts.");
          resolve(null);
        }
      });
    });
  }

  allowPostModeration(postid: string, userid: string): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(postid).update({ show: true, moderator: userid, reviewed: true });
  }

  addReport(post: Post): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(post.id).update({ reports: firebase.firestore.FieldValue.increment(1), show: !post.reports || post.reports < 5 || post.reviewed, reviewed: post.reviewed ? true : false });
  }

  removePost(postid: string, userid: string): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(postid).update({ show: false, moderator: userid, reviewed: true });
  }

  addOof(post: Post, userid: string): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(post.id).update({ oofs: firebase.firestore.FieldValue.arrayUnion(userid), points: firebase.firestore.FieldValue.increment(1) }).then(() => {
      return this.firestore.firestore.collection('users').doc(post.userid).update({ oofs: firebase.firestore.FieldValue.increment(1), points: firebase.firestore.FieldValue.increment(1) });
    });
  }

  addRip(post: Post, userid: string): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(post.id).update({ rips: firebase.firestore.FieldValue.arrayUnion(userid), points: firebase.firestore.FieldValue.increment(1) }).then(() => {
      return this.firestore.firestore.collection('users').doc(post.userid).update({ rips: firebase.firestore.FieldValue.increment(1), points: firebase.firestore.FieldValue.increment(1) });
    });
  }

  addF(post: Post, userid: string): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(post.id).update({ fs: firebase.firestore.FieldValue.arrayUnion(userid), points: firebase.firestore.FieldValue.increment(1) }).then(() => {
      return this.firestore.firestore.collection('users').doc(post.userid).update({ fs: firebase.firestore.FieldValue.increment(1), points: firebase.firestore.FieldValue.increment(1) });
    });
  }

  removeOof(post: Post, userid: string): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(post.id).update({ oofs: firebase.firestore.FieldValue.arrayRemove(userid), points: firebase.firestore.FieldValue.increment(-1) }).then(() => {
      return this.firestore.firestore.collection('users').doc(post.userid).update({ oofs: firebase.firestore.FieldValue.increment(-1), points: firebase.firestore.FieldValue.increment(-1) });
    });
  }

  removeRip(post: Post, userid: string): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(post.id).update({ rips: firebase.firestore.FieldValue.arrayRemove(userid), points: firebase.firestore.FieldValue.increment(-1) }).then(() => {
      return this.firestore.firestore.collection('users').doc(post.userid).update({ rips: firebase.firestore.FieldValue.increment(-1), points: firebase.firestore.FieldValue.increment(-1) });
    });
  }

  removeF(post: Post, userid: string): Promise<any> {
    return this.firestore.firestore.collection('nsfw').doc(post.id).update({ fs: firebase.firestore.FieldValue.arrayRemove(userid), points: firebase.firestore.FieldValue.increment(-1) }).then(() => {
      return this.firestore.firestore.collection('users').doc(post.userid).update({ fs: firebase.firestore.FieldValue.increment(-1), points: firebase.firestore.FieldValue.increment(-1) });
    });
  }

  getPost(postid: string): Promise<Post> {
    return new Promise((resolve, reject) => {
      this.firestore.firestore.collection('nsfw').doc(postid).get().then((data) => {
        let post: Post = <Post>{ id: data.id, ...data.data() };
        resolve(post);
      });
    });
  }

  getForModeration(): Promise<Post[]> {
    return new Promise((resolve, reject) => {
      this.firestore.firestore.collection('nsfw').where('reports', '>', 0).where('reviewed', '==', false).orderBy('reports', 'desc').get().then((docs) => {
        if (docs.empty) {
          resolve([]);
        } else {
          let posts: Post[] = [];
          docs.forEach((doc) => {
            posts.push(<Post>{ id: doc.id, ...doc.data() });
          });
          resolve(posts);
        }
      });
    });
  }

  getMostRecent(): Promise<Post[]> {
    return new Promise((resolve, reject) => {
      this.firestore.firestore.collection('nsfw').orderBy('date', 'desc').limit(15).get().then((data) => {
        if (data.empty) {
          resolve([]);
        } else {
          let posts: Post[] = [];
          data.docs.forEach((doc) => {
            let post: Post = <Post>{ id: doc.id, ...doc.data() };
            posts.push(post);
          });
          resolve(posts);
        }
      });
    });
  }

  getMoreRecents(fromDate: number): Promise<Post[]> {
    return new Promise((resolve, reject) => {
      this.firestore.firestore.collection('nsfw').where('date', '<', fromDate).orderBy('date', 'desc').limit(15).get().then((data) => {
        if (data.empty) {
          resolve([]);
        } else {
          let posts: Post[] = [];
          data.docs.forEach((doc) => {
            let post: Post = <Post>{ id: doc.id, ...doc.data() };
            posts.push(post);
          });
          resolve(posts);
        }
      });
    });
  }
}
