import { Injectable } from '@angular/core';
import { ToastController, AlertController, LoadingController } from '@ionic/angular'
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  constructor(private user: UserService, private auth: AuthService, private loading: LoadingController, private toast: ToastController, private alert: AlertController) { }

  async showToast(sMessage: string) {
    const oToast = await this.toast.create({
      message: sMessage,
      duration: 3000,
      position: 'bottom'
    });
    oToast.present();
  }

  async showLoading() {
    const loading = await this.loading.create({
      spinner: "lines"
    });
    loading.present();
  }

  async hideLoading() {
    this.loading.dismiss();
  }

  async presentGenericAlert(sTitle: string, sMessage: string) {
    const alert = await this.alert.create({
      header: sTitle,
      message: sMessage,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentErrorAlert(sMessage: string) {
    const alert = await this.alert.create({
      header: 'Error',
      message: sMessage,
      buttons: ['OK']
    });

    await alert.present();
  }

  async presentGenericConfirmationAlert(sTitle: string, sMessage: string, onAccept: Function) {
    const alert = await this.alert.create({
      header: sTitle,
      message: sMessage,
      buttons: [{
        text: "No",
        role: "cancel"
      }, {
        text: "Yes",
        handler: () => {
          onAccept();
        }
      }]
    });

    await alert.present();
  }

  async presentVerifyRegistrationAlert(email?: string, password?: string) {
    const alert = await this.alert.create({
      header: 'Verification Required',
      message: 'A verification email has been sent. Please verify your account before logging in. (Make sure to check your spam folder!)',
      buttons: [{
        text: "Verified",
        cssClass: 'alert-primary',
        handler: () => {
          this.presentLoginAlert(email, password);
        }
      }]
    });

    await alert.present();
  }

  async presentRegistrationAlert(email, password) {
    const alert = await this.alert.create({
      header: 'Register',
      inputs: [
        {
          name: 'email',
          value: email,
          placeholder: 'Email'
        },
        {
          name: 'username',
          type: 'text',
          placeholder: 'Username'
        },
        {
          name: 'password',
          type: 'password',
          value: password,
          placeholder: "Password"
        },
        {
          name: 'confirm',
          type: 'password',
          placeholder: 'Confirm Password'
        }
      ],
      buttons: [
        {
          text: 'Login',
          cssClass: 'alert-primary',
          handler: () => {
            alert.dismiss();
            this.presentLoginAlert();
          }
        },
        {
          text: "Register",
          cssClass: 'alert-secondary',
          handler: (data) => {
            if (data.email !== null && data.email !== '' && data.password !== null && data.password !== '' && data.confirm !== null && data.confirm !== '' && data.username !== null && data.username !== '') {
              if (data.password == data.confirm) {
                if (data.username.search(/^(?=.{3,20}$)[a-zA-Z0-9]+(?:_?[a-zA-Z0-9]+)+$/) != 0) {
                  this.showToast("Username is invalid. Must be 3-20 characters, alphanumeric with underscores.");
                  return false;
                } else {
                  return this.auth.isUsernameAvailable(data.username).then((result) => {
                    if (result) {
                      this.auth.registerWithEmail(data.email, data.username, data.password).then(() => {
                        alert.dismiss();
                        this.presentVerifyRegistrationAlert(data.email, data.password);
                      }).catch((err) => {
                        this.showToast("Error registering. Please try again.");
                        return false;
                      });
                    } else {
                      this.showToast("That username is taken. Please try another.");
                      return false;
                    }
                  });
                }
              } else {
                this.showToast("Passwords do not match.");
                return false;
              }
            } else {
              this.showToast("Please fill out each field.");
              return false;
            }
          }
        }
      ]
    });

    await alert.present();
  }

  async presentLoginAlert(email?: string, password?: string, reset?: boolean) {
    let withResetButtons = [
      {
        text: 'Register',
        cssClass: 'alert-primary',
        handler: (data) => {
          alert.dismiss();
          this.presentRegistrationAlert(data.email, data.password);
        }
      },
      {
        text: "Reset Password",
        cssClass: 'alert-secondary',
        handler: (data) => {
          if (data.email && data.email != '') {
            this.auth.resetPassword(data.email).then(() => {
              this.showToast(`Password reset link send to ${data.email}.`);
            }).catch((err) => {
              this.showToast("Unable to send password reset email. Double check your email address and try again.");
              this.presentLoginAlert(data.email, data.password, true);
            });
          } else {
            this.showToast("Enter your email and try again.");
            this.presentLoginAlert(data.email, data.password, true);
          }
        }
      },
      {
        text: 'Login',
        cssClass: 'alert-secondary',
        handler: (data) => {
          if (data.email != '' && data.password != '') {
            this.auth.loginUser(data.email, data.password).then((result) => {
              if (result.user.emailVerified) {
                this.user.initUserData();
              } else {
                alert.dismiss();
                this.presentVerifyRegistrationAlert(data.email, data.password);
              }
            }).catch((error) => {
              console.log(error);
              let pass = false;
              if (error.code == "auth/wrong-password") {
                this.showToast("Email and password do not match.");
                pass = true;
              } else if (error.code == "auth/invalid-email") {
                this.showToast("Invalid email.");
              } else if (error.code == "auth/user-not-found") {
                this.showToast("No account exists for that email.");
              } else {
                this.showToast("Error logging in. Please try again.");
              }

              this.presentLoginAlert(data.email, data.password, pass);
            });
          } else {
            this.showToast("Please fill out both email and password.");
            return false;
          }
        }
      }
    ];

    let withoutResetButtons = [
      {
        text: 'Register',
        cssClass: 'alert-primary',
        handler: (data) => {
          alert.dismiss();
          this.presentRegistrationAlert(data.email, data.password);
        }
      },
      {
        text: 'Login',
        cssClass: 'alert-secondary',
        handler: (data) => {
          if (data.email != '' && data.password != '') {
            this.auth.loginUser(data.email, data.password).then((result) => {
              if (result.user.emailVerified) {
                this.user.initUserData();
              } else {
                alert.dismiss();
                this.presentVerifyRegistrationAlert(data.email, data.password);
              }
            }).catch((error) => {
              console.log(error);
              let pass = false;
              if (error.code == "auth/wrong-password") {
                this.showToast("Email and password do not match.");
                pass = true;
              } else if (error.code == "auth/invalid-email") {
                this.showToast("Invalid email.");
              } else if (error.code == "auth/user-not-found") {
                this.showToast("No account exists for that email.");
              } else {
                this.showToast("Error logging in. Please try again.");
              }

              this.presentLoginAlert(data.email, data.password, pass);
            });
          } else {
            this.showToast("Please fill out both email and password.");
            return false;
          }
        }
      }
    ];

    const alert = await this.alert.create({
      header: "Login",
      inputs: [
        {
          name: 'email',
          type: 'text',
          placeholder: 'Email',
          value: email
        },
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password',
          value: password
        }
      ],
      buttons: reset ? withResetButtons : withoutResetButtons
    });

    await alert.present();
  }

  async presentVerifyLoginAlert(oFirebaseUser: any) {
    const alert = await this.alert.create({
      header: 'Verification Required',
      message: 'You need to verify your email before logging in. Please check your spam folder, or send a new email.',
      buttons: [{
        text: "Send Email",
        handler: () => {
          this.auth.sendVerificationEmail(oFirebaseUser);
          alert.dismiss();
        }
      }, {
        text: "Verified",
        handler: () => {
          alert.dismiss();
          this.presentLoginAlert();
        }
      }]
    });

    await alert.present();
  }
}
