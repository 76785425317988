// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA1H-ohm99k4ZY-W67U5g9L25-fkVerS0Y",
    authDomain: "oof-rip.firebaseapp.com",
    databaseURL: "https://oof-rip.firebaseio.com",
    projectId: "oof-rip",
    storageBucket: "oof-rip.appspot.com",
    messagingSenderId: "129789400210",
    appId: "1:129789400210:web:3bbf917da3e43a85e7c24b",
    measurementId: "G-RRNFD4WBPR"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
