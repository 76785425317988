import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { PopoverController, NavController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-profilepop',
  templateUrl: './profilepop.component.html',
  styleUrls: ['./profilepop.component.scss'],
})
export class ProfilepopComponent {

  constructor(private auth: AuthService, private pop: PopoverController, private nav: NavController, private navParams: NavParams) { }

  openProfile() {
    this.nav.navigateForward('/user/' + this.navParams.data.userid);
    this.pop.dismiss();
  }

  logout() {
    this.auth.logoutUser();
    this.pop.dismiss();
  }

}
