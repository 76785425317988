import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { PostService } from '../post.service';
import { NsfwpostService } from '../nsfwpost.service';
import { InformationService } from '../information.service';
import domtoimage from 'dom-to-image';

@Component({
  selector: 'app-optionspop',
  templateUrl: './optionspop.component.html',
  styleUrls: ['./optionspop.component.scss'],
})
export class OptionspopComponent {

  oPost: Post = null;
  oUser: User = null;
  bNSFW: boolean = false;
  constructor(private pop: PopoverController, private postService: PostService, private nsfwService: NsfwpostService, private navParams: NavParams, private info: InformationService) {
    this.oPost = this.navParams.data.post;
    this.oUser = this.navParams.data.user;
    this.bNSFW = this.navParams.data.nsfw ? this.navParams.data.nsfw : false;
  }

  downloadPost() {
    let div = document.getElementById(this.oPost.id);
    let newDiv = <HTMLElement>div.cloneNode(true);
    newDiv.setAttribute('style', 'max-width: 400px;');
    let site = document.createElement("span");
    site.innerHTML = this.bNSFW ? "https://oof.rip/nsfw" : "https://oof.rip";
    site.setAttribute('style', "vertical-align: top; float: right;");
    let react = newDiv.getElementsByClassName("react-container")[0];
    react.appendChild(site);
    document.body.appendChild(newDiv);

    domtoimage.toPng(newDiv).then((dataURI) => {
      console.log(dataURI);
      var link = document.createElement("a");
      link.download = "post_" + this.oPost.id + ".png";
      link.href = dataURI;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      document.body.removeChild(newDiv);
      this.pop.dismiss();
    }).catch((err) => {
      document.body.removeChild(newDiv);
      this.info.showToast("Error creating image. Please try again.");
    });
  }

  reportPost() {
    if (this.bNSFW){
      this.nsfwService.addReport(this.oPost).then(() => {
        this.info.showToast("Post reported.");
        this.pop.dismiss();
      }).catch((err) => {
        this.info.showToast("Report failed to submit. Please try again.");
      });
    } else {
      this.postService.addReport(this.oPost).then(() => {
        this.info.showToast("Post reported.");
        this.pop.dismiss();
      }).catch((err) => {
        this.info.showToast("Report failed to submit. Please try again.");
      });
    }
  }

  removePost() {
    if (this.bNSFW){
      this.nsfwService.removePost(this.oPost.id, this.oUser.id).then(() => {
        this.pop.dismiss({removed: true});
      }).catch((err) => {
        this.info.showToast("Failed to remove post.");
      });
    } else {
      this.postService.removePost(this.oPost.id, this.oUser.id).then(() => {
        this.pop.dismiss({removed: true});
      }).catch((err) => {
        this.info.showToast("Failed to remove post.");
      });
    }
  }

}
