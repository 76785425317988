import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { AppComponent } from './app.component';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private oUser: Observable<User>;
	// public oAppInstance: AppComponent;
	public hasInit: boolean = false;
	private oObserver: Subscriber<User> = null;

	constructor(private auth: AuthService, private firestore: AngularFirestore) { }

	initUserData(): Promise<void> {
		this.hasInit = true;
		return new Promise((resolve, reject) => {
			this.oUser = new Observable((userObs) => {
				this.oObserver = userObs;

				this.auth.oFirebaseUser.subscribe((oFirebaseUser) => {
					if (oFirebaseUser != null && oFirebaseUser.emailVerified) {
						this.firestore.firestore.collection('users').doc(oFirebaseUser.uid).get().then((results) => {
							let oUser = <User>{ id: oFirebaseUser.uid, ...results.data() };

							this.oObserver.next(oUser);
						});
					} else {
						this.oObserver.next(null);
					}

					resolve();
				});
			});
		});
		// return new Promise((resolve, reject) => {
		// 	let me = this;
		// 	this.auth.getUser().then((oFirebaseUser) => {
		// 		this.firestore.collection('users').doc(oFirebaseUser.uid).snapshotChanges().subscribe((results) => {
		// 			me.oUser = <User>{ id: oFirebaseUser.uid, ...results.payload.data() };

		// 			if (this.oAppInstance != null)
		// 				this.oAppInstance.oUser = me.oUser;


		// 			resolve(me.oUser);
		// 		});
		// 	});
		// });
	}

	getUserObservable(): Promise<Observable<User>> {
		return new Promise((resolve, reject) => {
			if (this.hasInit && this.oUser) {
				resolve(this.oUser);
			} else {
				this.initUserData().then(() => {
					resolve(this.oUser);
				});
			}
		});
	}

	getUserByID(userid: string): Promise<User> {
		return new Promise((resolve, reject) => {
			this.firestore.firestore.collection('users').doc(userid).get().then((data) => {
				if (data.exists) {
					let user: User = <User>{ id: data.id, ...data.data() };
					delete user.email;
					resolve(user);
				} else {
					resolve(null);
				}
			});
		});
	}

	getMostPopular(): Promise<User[]> {
		return new Promise((resolve, reject) => {
			this.firestore.firestore.collection('users').orderBy('points', 'desc').limit(3).get().then((docs) => {
				if (docs.empty) {
					resolve([]);
				} else {
					let users: User[] = [];
					docs.forEach((doc) => {
						users.push(<User>{ id: doc.id, ...doc.data() });
					});
					resolve(users);
				}
			});
		});
	}

	saveNSFWPreference(userid: string): Promise<any> {
		return this.firestore.firestore.collection('users').doc(userid).update({ allowNSFW: true });
	}
}
