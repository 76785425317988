import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { AlertController, ToastController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public oFirebaseUser: Observable<firebase.User> = null;
	private oAuthSub = null;

	constructor(private auth: AngularFireAuth, private firestore: AngularFirestore, private toast: ToastController, private alert: AlertController) { }

	initAuth(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.oFirebaseUser = this.auth.user;
			resolve();
		});
	}

	// deleteUserLocal() {
	// 	this.storage.remove("user");
	// }

	// saveUserLocal(oUser: User) {
	// 	this.storage.set("user", oUser);
	// }

	// getUserFromLocal(): Promise<User> {
	// 	return new Promise((resolve, reject) => {
	// 		this.storage.get("user").then((oUser: User) => {
	// 			resolve(oUser);
	// 		})
	// 	});
	// }

	// getUser(): Promise<firebase.User> {
	// 	let me = this;
	// 	return new Promise((resolve, reject) => {
	// 		if (this.oAuthSub != null) {
	// 			resolve(this.oFirebaseUser);
	// 		} else {
	// 			resolve(null);
	// 		}
	// 	});
	// }

	isUsernameAvailable(username: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.firestore.firestore.collection('users').where('username', '==', username).get().then((snapshot) => {
				if (snapshot.empty) {
					resolve(true);
				} else {
					resolve(false);
				}
			});
		});
	}

	registerWithEmail(email: string, username: string, password: string): Promise<any> {
		return this.auth.auth.createUserWithEmailAndPassword(email, password).then((data) => {
			let cred = data;
			data.user.sendEmailVerification().then(() => {
				this.firestore.collection('users').doc(cred.user.uid).set({ username: username }, { merge: true });
				cred.user.updateProfile({ displayName: username });
			}).then(() => {
				this.registerUser(data.user);
			});
		});
	}

	sendVerificationEmail(oFirebaseUser: any): Promise<any> {
		return oFirebaseUser.sendEmailVerification();
	}

	loginUser(email: string, password: string): Promise<any> {
		return this.auth.auth.signInWithEmailAndPassword(email, password);
	}

	loginAnon(): Promise<any> {
		return this.auth.auth.signInAnonymously();
	}

	resetPassword(email: string): Promise<void> {
		return this.auth.auth.sendPasswordResetEmail(email);
	}

	logoutUser(): Promise<void> {
		// this.deleteUserLocal();
		return this.auth.auth.signOut();
	}

	loginWithFacebook(): Promise<firebase.User> {
		return new Promise((resolve, reject) => {
			return this.auth.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((data) => {
				//See if this user is in the DB
				this.firestore.firestore.collection('users').doc(data.user.uid).get().then(async (res) => {
					if (res.exists) {
						resolve(data.user);
					} else {
						const username = await this.alert.create({
							header: "Username Required",
							message: "Thanks for logging in through facebook! Please choose a unique username. This cannot be changed later.",
							backdropDismiss: false,
							inputs: [{
								placeholder: "username",
								name: "username"
							}],
							buttons: [{
								text: "Submit",
								handler: (ldata) => {
									if (ldata.username == null || ldata.username == "" || ldata.username.search(/^(?=.{3,20}$)[a-zA-Z0-9]+(?:_?[a-zA-Z0-9]+)+$/) != 0) {
										this.showToast("Invalid username. Must be at least 3 characters. May contain letters, numbers, and underscores.");
										return false;
									} else {
										//check if it exists
										this.isUsernameAvailable(ldata.username).then((res) => {
											if (res) {
												this.registerFacebookUser(data.user, ldata.username).then(() => {
													resolve(data.user);
												});
											} else {
												this.showToast("That username is taken. Try another.");
												return false;
											}
										});
									}
								}
							}]
						});
						await username.present();
					}
				});
			}).catch(error => {
				console.log(error);
				reject(error);
			});
		});
	}

	private registerUser(oUser: firebase.User): Promise<any> {
		return this.firestore.firestore.collection('users').doc(oUser.uid).set({
			email: oUser.email,
			joindate: Date.now(),
			oofs: 0,
			rips: 0,
			fs: 0
		},
			{
				merge: true
			});
	}

	private registerFacebookUser(oUser: firebase.User, sUsername: string): Promise<any> {
		return this.firestore.firestore.collection('users').doc(oUser.uid).set({
			displayname: sUsername,
			username: sUsername,
			email: oUser.email,
			prestige: 100,
			joindate: Date.now()
		},
			{
				merge: true
			});
	}

	private async showToast(sMessage: string) {
		const oToast = await this.toast.create({
			message: sMessage,
			duration: 2000,
			position: 'bottom'
		});
		oToast.present();
	}
}
