import { Component } from '@angular/core';
import { UserService } from './user.service';
import { Platform } from '@ionic/angular';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private user: UserService,
    private auth: AuthService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.auth.initAuth().then(() => {
      this.user.initUserData();
    });
  }
}
